import * as introJs from 'intro.js'

(function() {

  var firstQuestionHeading = document.querySelector('.list-of-questions .panel-heading');

  function startIntro() {
    introJs()
      .setOptions({
        steps: [
          {
            intro:  "<p>Hallo daar! Je bent nu op de pagina waar je jouw opdracht gaat inleveren!</p>" +
                    "<p>Ik wil je graag laten zien waar alles zit. Klik op <em>Volgende</em> voor een korte rondleiding.</p>" +
                    "<p>Weet je alles al? Klik dan op <em>Overslaan</em>.</p>"
          },
          {
            element: document.querySelector('.panel-body'),
            intro:  "<h4>Dit is de inleiding</h4>" +
                    "<p>Lees deze helemaal door en zorg dat je begrijpt wat er staat.</p>"
          },
          {
            element: firstQuestionHeading,
            intro:  "<h4>Daarna begin je aan de eerste vraag</h4>" +
                    "<p>Klik op de vraag om alle tekst open te klappen</p>" +
                    "<p>De vraag komt dan in beeld, jij leest die goed door en je gaat nadenken over het goede antwoord.</p>"
          },
          {
            element: document.querySelector('.student-answer'),
            intro:  "<h4>Jouw antwoord schrijf je in dit vakje</h4>" +
                    "<p>Je moet het meteen hier intypen. De tekst wordt automatisch bewaard, handig!</p>" +
                    "<p>Als je wil kun je ook een foto of bijlage meesturen. Hoe? Ik laat het je zien, druk op <em>Volgende</em>!"
          },
          {
            element: document.querySelector('.re-button.re-image'),
            intro:  "<h4>Foto of illustratie meesturen</h4>" +
                    "<p>Soms moet je geen tekst inleveren, maar een foto. Dat kan met deze knop. Meer dan een mag ook.</p>"
          },
          {
            element: document.querySelector('.re-button.re-file'),
            intro:  "<h4>Bijlage meesturen</h4>" +
                    "<p>Als je een Word bijlage wil inleveren, of een andere soort bijlage, druk je op deze knop.</p>" +
                    "<p><em>En zo ga je alle vragen af, van boven naar beneden.</em></p>"
          },
          {
            element: document.querySelector('#some_answers_missing img'),
            intro:  "<h4>Inleveren! Bijna 👻</h4>" +
                    "<p>Pas als je alle vragen af hebt, verschijnt hier een rode knop.</p>" +
                    "<p>Op die knop staat <strong>Inleveren</strong> en als je daarop drukt ben je klaar!</p>"
          },
          {
            element: document.querySelector('.introjs_again_submit_task'),
            intro:  "<h4>En nu jij!</h4>" +
                    "<p>Als je een antwoord niet weet, vraag dan je via het antwoordvakje om hulp aan je TOP-leraar.</p>" +
                    "<p><em>Wil je deze rondleiding nog eens lezen? Klik op de wereldbol! Succes ermee!</em></p>"
          }
        ],
        showStepNumbers: false,
        showBullets: false,
        nextLabel: 'Volgende',
        prevLabel: 'Vorige',
        doneLabel: 'Klaar',
        skipLabel: 'Overslaan'
      })
      .start()

    localStorage.setItem('introjs_tour_submit_task', new Date())
  }

  function startWithState() {
    var pageHasIntro = document.querySelector('.introjs_tour.introjs_tour_submit_task')
    var neverSeen = !localStorage.getItem('introjs_tour_submit_task')

    if (pageHasIntro && neverSeen) {
      startIntro()
    }
  }

  // bypass the stored state, because this is user-initiated.
  function onDemand(event) {
    if(event.target.classList.contains('introjs_again_submit_task')) {
      event.preventDefault()
      startIntro()
    }
  }

  document.addEventListener('click', onDemand)
  startWithState()
})();
