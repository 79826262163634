import Chartkick from 'chartkick'
import Chart from 'chart.js'

window.Chartkick = Chartkick
Chartkick.addAdapter(Chart)

var chartFunc = (function () {
  function selShow (element) {
    var percentage = parseInt(element.getAttribute('data-percentage'), 10)
    var label = document.querySelector('.label')
    var color = label ? window.getComputedStyle(label).backgroundColor : '#9c9'
    var showChart = new Chart(element, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [percentage, 100 - percentage],
            backgroundColor: [color, '#e8e6df'],
            hoverBackgroundColor: [color, '#e8e6df'],
            borderWidth: 0
          }
        ]
      },
      options: {
        responsive: true,
        aspectRatio: 1,
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        },
        cutoutPercentage: 60,
        plugins: {
          doughnutlabel: {
            labels: [
              {
                text: percentage.toString() + '%',
                font: {
                  size: '30'
                }
              }
            ]
          }
        }
      }
    })
  }

  return {
    selShow: selShow
  }
})();

var selShowChart = document.getElementById('progress-chart-show')
if(selShowChart) chartFunc.selShow(selShowChart)
